<template>
    <div class="about_page">
        <m-custom-head></m-custom-head>
        <img :src="mobileCosBaseUrl+'about_banner_img.png'" class="banner_img" alt="">
        <div class="about_text_wrap">
            <p>解套GETO致力于打造好玩有趣、年轻时尚的两性情趣生活品牌，我们在甜酷女孩的生活视角中汲取灵感，倡导用积极乐观、激情洒脱的生活态度去面对现实中的困惑，相信一切都能迎刃而解。一个更懂女生的情趣品牌，也要让男生更懂女生的情趣品牌。</p>
            <div class="tag_wrap flex_align_center flex_justify_between">
                <img src="@/assets/pc/img/tag_2.png" class="tag_2" alt="">
                <img src="@/assets/pc/img/about_tag.png" class="about_tag" alt="">
            </div>
        </div>
        <div class="big_img" :style="{backgroundImage: 'url('+mobileCosBaseUrl+'about_big_img_1.png)'}"></div>
        <div class="pack_wrap">
            <h1>这一次</br>重新定义两性生活</h1>
            <div class="pack_img_wrap">
                <img v-lazy="mobileCosBaseUrl+'about_pack_img.png'" alt="" class="pack_img">
                <div class="pack_content flex_col flex_justify_between">
                    <h2>MINI信封包装</h2>
                    <p>通过极具创意特色的“外衣”改变</br>人们对于安全套的刻板印象。</br>这一次</br>我们选择mini时尚的信封包装，</br>让套套不必蒙上羞耻面纱</br>邀请多名设计师联合创作‘姿</br>识’卡片</br>使用户收到的每一张盲卡都变成</br>一份惊喜</p>
                </div>
            </div>
            <img v-lazy="mobileCosBaseUrl+'about_pack_mini_img.png'" class="mini_img" alt="">
        </div>
        <div class="originality_wrap flex_col flex_align_center">
            <img v-lazy="mobileCosBaseUrl+'about_originality_img.png'" class="originality_img" alt="">
            <div class="title_wrap flex_justify_between dot">
                <span>好玩有趣</span>
                <span>创意周边</span>
            </div>
            <img src="@/assets/mobile/img/fun_and_create.png" class="fun_and_create" alt="">
            <p>从都市青年的视角出发打破市场上</br>对于两性生活用品的陈旧认知</br>融入多元的青年文化</br>创造出好玩有趣的周边产品</br>帮助年轻人摆脱束缚</br>做最真实自在的自己</p>
            <img src="@/assets/mobile/img/tag_5.png" class="tag_5" alt="">
            <img src="@/assets/mobile/img/rotate_tag_2.png" alt="" class="rotate_tag rotate">
        </div>
        <div class="security_wrap flex_col flex_align_center">
            <img v-lazy="mobileCosBaseUrl+'about_security_img.png'" class="security_img" alt="">
            <div class="title_wrap flex_justify_between dot">
                <span>安全</span>
                <span>可靠</span>
            </div>
            <p>通过与国外优质原材料供应商、国内</br>高端安全套生产企业协作的形式打造</br>出专属于国人的时尚两性生活品牌。</br>严格遵循生产质量以及管理体系，保</br>证用户的愉悦体验。</p>
        </div>
        <div class="future_wrap flex_col flex_align_center">
            <img v-lazy="mobileCosBaseUrl+'about_future_img.png'" class="future_img" alt="">
            <div class="title_wrap flex_justify_between">
                <span>FUTURE</span>
                <span>未来</span>
            </div>
            <img src="@/assets/mobile/img/tag_6.png" class="tag_6" alt="">
            <p>如何满足年轻人更开放、更多样、更</br>高品质的生活需求</br>是GETO解套坚持不懈的发展方向</br>相信NEXT TIME WILL BE SPECIAL</p>
        </div>
        <m-custom-foot></m-custom-foot>
    </div>
</template>

<script>
    import { baseCosBaseUrl, mobileCosBaseUrl, pcCosBaseUrl } from '@/config/evn.js'
export default {
    name: 'about',
    data() {
        return {
            mobileCosBaseUrl
        }
    },
}
</script>

<style lang="scss" scoped>
    @-webkit-keyframes rotate {
        0% {
            transform: rotate();
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .rotate {
        animation: rotate 3s infinite linear;
    }

    .about_page {
        .dot {
            position: relative;
            &::before {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 6px;
                    height: 6px;
                    background-color: #111;
                }
        }
        .banner_img {
            width: 718px;
            height: 833px;
            margin: 0 auto;
        }
        .about_text_wrap {
            height: 615px;
            padding: 48px 158px 0;
            p {
                line-height: 48px;
                font-family: SourceHanSansCN-Regular;
                font-size: 24px;
                letter-spacing: 1px;
                text-align: left;
            }
            .tag_wrap {
                margin-top: 22px;
                .tag_2 {
                    width: 96px;
                    height: 19px;
                }
                .about_tag {
                    width: 122px;
                    height: 42px;
                }
            }
        }
        .big_img {
            height: 421px;
            background-size: 100% 100%;
        }
        .pack_wrap {
            height: 890px;
            color: #fff;
            background-color: #181818;
            padding: 24px 0 0 16px;
            h1 {
                line-height: 39px;
                font-size: 26px;
                letter-spacing: 4px;
                text-align: left;
            }
            .pack_img_wrap {
                display: flex;
                height: 504px;
                margin-top: 24px;
                .pack_img {
                    width: 336px;
                    height: 100%;
                    margin-right: 24px;
                }
                .pack_content {
                    h2 {
                        line-height: 43px;
                        font-family: HurmeGeometricSans3-Bold;
                        font-size: 36px;
                    }
                    p {
                        line-height: 48px;
                        font-family: PingFang-Medium;
                        font-size: 24px;
                        color: #EBEBEB;
                    }
                }
            }
            .mini_img {
                width: 180px;
                height: 172px;
                margin: 60px 32px 0 auto;
            }
        }
        .originality_wrap, .security_wrap, .future_wrap {
            padding-top: 170px;
            p {
                line-height: 48px;
                font-family: SourceHanSansCN-Regular;
                font-size: 24px;
            }
        }
        .originality_wrap {
            position: relative;
            .originality_img {
                width: 613px;
                height: 287px;
            }
            .title_wrap {
                width: 330px;
                font-size: 36px;
                margin: 60px 0 18px;
            }
            .fun_and_create {
                width: 329px;
                height: 29px;
            }
            p {
                margin: 48px 0;
            }
            .tag_5 {
                width: 49px;
                height: 49px;
            }
            .rotate_tag {
                position: absolute;
                left: 45px;
                top: 143px;
                width: 69px;
                height: 69px;
            }
        }
        .security_wrap {
            .security_img {
                width: 430px;
                height: 574px;
            }
            .title_wrap {
                width: 182px;
                font-family: SourceHanSansCN-Bold;
                font-size: 36px;
                letter-spacing: 4px;
                margin: 48px 0;
            }
        }
        .future_wrap {
            padding-bottom: 156px;
            .future_img {
                width: 686px;
                height: 438px;
            }
            .title_wrap {
                width: 265px;
                line-height: 43px;
                font-family: HurmeGeometricSans3-Bold;
                font-size: 36px;
                margin: 60px 0 48px;
                span:last-child {
                    font-family: SourceHanSansCN-Bold;
                }
            }
            .tag_6 {
                width: 60px;
                height: 60px;
            }
            p {
                margin-top: 48px;
            }
        }
    }
</style>